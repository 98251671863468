import React, { Dispatch, useEffect, useState } from 'react';
import { Modal } from '../../ui/Modal/Modal';
import styles from './SearchModal.module.scss';
import { Input } from "@kursk/components/ui/Input/Input";
import { Button } from "@kursk/components/ui/Button/Button";
import Link from "next/link";
import { useRouter } from "next/router";
import { themeValue } from "@kursk/themes";
import { SuggestionList } from "@kursk/components/pages/Search/SuggestionsList/SuggestionList";
import { loadSearchResults, searchSlice } from "@common/redux/slices/search.slice";
import { useDispatch } from "react-redux";
import { useSelectSlice } from "@common/redux/selectors/useSelectSlice";
import { useOrientations } from "@common/hooks/useOrientation";
import { SearchIcon } from "@kursk/components/ui/icons";
import classNames from "classnames";
import _ from "lodash";

export interface AuthModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<React.SetStateAction<boolean>>;
}

export const SearchModal = ({
  isOpen,
  setIsOpen,
}: AuthModalProps) => {
  const router = useRouter();
  const dispatch = useDispatch()
  const orientation = useOrientations();
  const [searchQuery, setSearchQuery] = useState<string>(router.query.nameQuery as string || '')
  const [suggestionBlockOpened, setSuggestionBlockOpened] = useState<boolean>(false);

  const { hints } = useSelectSlice(searchSlice);
  const { items } = hints;

  const onClose = () => {
    setIsOpen(false);
  };

  const openSearchPage = () => {
    setIsOpen(false);
    return;
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && searchQuery?.length > 0) {
      setIsOpen(false);
      router.push(`/search?nameQuery=${searchQuery}`)
    }
  }

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (nameQuery) => {
      dispatch(loadSearchResults({ entityType: 'hints', nameQuery }));
    }

    return _.debounce(loadOptions, 300);
  }, [])

  const handleSearchQueryChanged = (event) => {
    setSearchQuery(event.target.value)
    setSuggestionBlockOpened(true)
    if (event.target.value.length > 3) debounceFetcher(event.target.value)
  }

  useEffect(() => {
    setSuggestionBlockOpened(false)
  }, [router])

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} isBackGroundWhite={false} closeIcon={false}>
      <div className={styles.wrapper}>
        <div className={styles.input_wrapper}>
          <Input
            onChange={handleSearchQueryChanged}
            close
            back
            iconWrapper='iconWrapper_modal'
            value={searchQuery}
            handleOnClick={setSearchQuery}
            handleOnBackClick={setIsOpen}
            offset='offset'
            placeholder={themeValue({
              bel: 'Поиск',
              default: ''
            })}
            className={!searchQuery && styles.hideIcon}
            autoFocus
            onKeyDown={(event) => onKeyDown(event)}
          />
          {suggestionBlockOpened && searchQuery.length > 0 && <SuggestionList
            items={items}
            searchQuery={searchQuery}
            className={styles.suggestionsBlock}
            totalItemClassname={styles.totalItemClassname}
            setIsOpen={setIsOpen}
          />}
        </div>
        <Link href={`/search?nameQuery=${searchQuery}`}>
          {orientation !== "mobile" ? <Button
            className={classNames(styles.button)}
            text={'НАЙТИ'}
            buttonType='greenPrimarySearch'
            onClick={openSearchPage}
            disabled={!searchQuery}
          /> :
            <button
              className={styles.iconButton}
              disabled={!searchQuery}
              onClick={openSearchPage}>
              <SearchIcon
                height={20}
                width={20}
              />
            </button>}
        </Link>
      </div>
    </Modal>
  )
}
