import React, { useEffect, useState } from 'react';
import axios from "axios";
import classNames from 'classnames';
import { LiveWidgetIcon } from "@kursk/components/ui/icons";
import { getConfig } from "@root/config/config";
import styles from './LiveButton.module.scss';

const LiveButton = () => {

  const [goto, setGoto] = useState<string | null>(null)

  const fetchIsLive = () => {
    const now = new Date().getTime()
    axios.get(`/api/1.0/streams?limit=1&offset=0&sort=-end&start=${now}&end=${now}&fields=_id`)
      .then(res => {
        if (res.data.total === 1) {
          setGoto(`/streams/${res.data.streams[0]._id}`)
        } else if (res.data.total > 1) {
          const enabled = getConfig().liveRedirect.enabled
          setGoto(enabled ? '/streams/live' : `/streams?isLive=true`)
        } else {
          setGoto(null)
        }
      })
  }

  useEffect(() => {
    fetchIsLive()
    const timerId = setInterval(() => fetchIsLive(), 60000)

    return () => clearInterval(timerId)
  }, [])

  if (!goto) return <></>

  return (
    <a target='_blank' href={goto} className={classNames(styles.button)} rel="noreferrer">
      <LiveWidgetIcon/>
    </a>
  );
}

export default LiveButton;
