import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import Link from 'next/link';
import Head from 'next/head';
import style from './Layout.module.scss';
import { MenuDropdownButton } from '../ui/MenuDropdownButton/MenuDropdownButton';
import { Notifications } from "@kursk/components/Layout/Notifications/Notifications";
import ScrollButton from './ScrollButton/ScrollButton';
import classNames from "classnames";
import { useSelectSlice } from "@common/redux/selectors/useSelectSlice";
import { authSlice, loadToken, signIn } from "@common/redux/slices/auth.slice";
import { regionContent } from '@kursk/content/regionContent';
import { sprojectsSlice } from '@common/redux/slices/sprojects.slice';
import { FooterComponent } from "@kursk/components/Layout/Footer/FooterComponent";
import { HeaderComponent } from "@kursk/components/Layout/Header/HeaderComponent";
import { UnisenderNewsPopup } from "@kursk/components/Layout/UnisenderNewsPopup/UnisenderNewsPopup";
import { prepareMenuLinks } from "@kursk/components/Layout/prepareMenuLinks";
import { RestorePasswordModal } from "@kursk/components/Layout/RestorePasswordModal/RestorePasswordModal";
import { SubscribeModal } from "@kursk/components/Layout/SubscribeModal/SubscribeModal";
import { LinkErrorModal } from "@kursk/components/Layout/LinkErrorModal/LinkErrorModal";
import { useRouter } from "next/router";
import { useDispatchThunk } from "@common/redux/dispatchThunk";
import LiveButton from "@kursk/components/Layout/LiveButton/LiveButton";
import { CookieAccept } from "@kursk/components/ui/CookieAccept/CookieAccept";
import Maintenance from "@kursk/components/Layout/Maintenance/Maintenance";
import { getCookie, setCookie } from "cookies-next";
import { notificationPush } from "@common/redux/slices/notification.slice";
import { useDispatch } from "react-redux";
import { subscribePostMessage, unSubscribePostMessage } from "@kursk/utils/postMessages";
import { esiaLogin } from "@common/utils/esia";
import { getConfig } from "@root/config/config";
import { Page } from "@kursk/components/seo/seo.types";

interface ILayoutProps {
  children: ReactNode | ReactElement | ReactElement[];
  showAuth?: boolean;
  showLiveWidget?: boolean;
  showSubscribePopup?: boolean;
  page?: Page;
}

export interface IMenuItem {
  title: string;
  to?: string;
  links?: IMenuItem[];
  target?: string;
  linksSource?: string;
  relativeLink?: string;
}

export function Layout({
  children,
  showAuth = false,
  showLiveWidget = false,
  showSubscribePopup = true,
  page,
}: ILayoutProps): ReactElement {
  const { isAuth } = useSelectSlice(authSlice);
  const { sprojects } = useSelectSlice(sprojectsSlice);

  const mainMenu = prepareMenuLinks(regionContent.header.links, { sprojects });
  const footerLinks = prepareMenuLinks(regionContent.footer.links, { sprojects });
  const router = useRouter();
  const dispatchThunk = useDispatchThunk();
  const dispatch = useDispatch();

  const [isBurgerOpen, setIsBurgerOpen] = useState<boolean>(false);
  const [showAcceptCookie, setShowAcceptCookie] = useState<boolean>(!getCookie('acceptedCookie'));
  const [isRestoreErrorModal, setIsRestoreErrorModal] = useState(false);
  const [isOpenRestorePasswordModal, setIsOpenRestorePasswordModal] = useState(false);
  const [restoreToken, setRestoreToken] = useState(null);

  const renderMenuLink = (item: IMenuItem) => {
    const checkIsLinkActive = () => false;
    const checkIsDropDownActive = () => false;

    return (
      item.links ? (
        <MenuDropdownButton
          className={classNames([
            style.header__menu__link,
            checkIsDropDownActive() && style.header__menu__link__active,
          ])}
          title={item.title}
          items={item.links}
          link={item.relativeLink}
          setIsBurgerOpen={setIsBurgerOpen}
        />
      ) : (
        <div
          key={item.title}
          className={classNames([style.header__menu__link, checkIsLinkActive() && style.header__menu__link__active])}
        >
          {item.target ?
            (<a
              className={style.header__menu__link_title}
              href={item.to}
              target={item.target}>{item.title}</a>) :
            (<Link href={item.to}>
              <a className={style.header__menu__link_title}>
                <span className={style.header__menu__link_text}>{item.title}</span>
              </a>
            </Link>)
          }
        </div>
      ))
  }

  React.useEffect(() => {
    dispatchThunk(loadToken({}));
  }, [isAuth]);

  React.useEffect(() => {
    isBurgerOpen === true ? document.body.classList.add('no-scroll') : document.body.classList.remove('no-scroll');
  }, [isBurgerOpen, isAuth]);

  useEffect(() => {
    if (router.query.register === 'success') {
      dispatchThunk(signIn({ token: router.query.token }));
    } else if (router.query.register === 'failed') {
      setIsRestoreErrorModal(true);
    } else if (router.query.restore === 'failed') {
      setIsRestoreErrorModal(true);
    } else if (router.query.restore === 'success') {
      setIsOpenRestorePasswordModal(true);
      setRestoreToken(router.query.token);
    }
  }, [router.query, isAuth]);

  const setAcceptCookie = () => {
    setShowAcceptCookie(false);
    setCookie('acceptedCookie', true);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (showAcceptCookie) {
      timer = setTimeout(() => {
        setAcceptCookie();
      }, 10000);
    }
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    subscribePostMessage('CultureWidget__esia_login_request', (data) => {
      esiaLogin(data.data);
    });
    subscribePostMessage('CultureWidget__esia_relogin_request', (data) => {

      esiaLogin(data.data, true);
    });

    return () => {
      unSubscribePostMessage('CultureWidget__esia_login_request');
      unSubscribePostMessage('CultureWidget__esia_relogin_request');
    };
  }, []);

  useEffect(() => {
    const url = new URL(window.location.toString());
    if (url.searchParams.has('__authSuccess')) {
      const authSuccess = Number(url.searchParams.get('__authSuccess'));
      let replaceUrl = window.location.pathname;
      if (authSuccess === 0) {
        dispatch(notificationPush({
          type: 'error',
          content: 'В процессе авторизации возникла ошибка.\nБыло отказано в предоставлении данных.'
        }));
      } else if (authSuccess === 1) {
        url.searchParams.delete('__authSuccess');
        replaceUrl = url.toString();
        dispatch(notificationPush({
          type: 'success',
          content: 'Вы успешно вошли в систему'
        }));
      }
      router.replace(replaceUrl, undefined, { shallow: true });
    }
  }, [])

  return (
    <>
      <RestorePasswordModal
        isOpen={isOpenRestorePasswordModal}
        setIsOpen={setIsOpenRestorePasswordModal}
        restoreToken={restoreToken}
      />
      {showSubscribePopup && !getConfig().subscribeDisabled && (
        <SubscribeModal />
      )}
      <LinkErrorModal isOpen={isRestoreErrorModal} onClose={() => setIsRestoreErrorModal(false)}/>

      <div className={style.layout}>
        <Head>
          <title>{regionContent.seo.pages.main.title}</title>
          <meta
            name="description"
            content={regionContent.seo.pages.main.description}/>
        </Head>
        <UnisenderNewsPopup
          id={regionContent.unisenderNewsPopup?.id}
        />
        <HeaderComponent
          isBurgerOpen={isBurgerOpen}
          mainMenu={mainMenu}
          setIsBurgerOpen={setIsBurgerOpen}
          renderMenuLink={renderMenuLink}
          showAuth={showAuth}
          page={page}
        />
        <div className={classNames([style.header__drawer, isBurgerOpen && style.open])}>
          <div className={style.header__drawerWrap}>
            {regionContent.useBvi && (
              <button
                onClick={() => setIsBurgerOpen(false)}
                className={classNames(style.header__bvi, 'bvi-open')}
              >
                <span>Версия для слабовидящих</span>
              </button>
            )}
            {isBurgerOpen &&
            <div className={style.header__drawerInner}>
              {mainMenu.map((item) => renderMenuLink(item))}
            </div>
            }
          </div>
        </div>
        <div className={style.content}>
          {children}
        </div>
        <div className={style.footer}>
          <FooterComponent links={footerLinks} useLogo={regionContent.footer.logoPath} page={page} />
        </div>
        <div suppressHydrationWarning={true}>
          <Notifications/>
        </div>
        {showLiveWidget && <LiveButton/>}
        {showAcceptCookie && (<CookieAccept setAcceptCookie={setAcceptCookie}/>)}
        <Maintenance />
        <ScrollButton/>
      </div>
    </>
  );
}

