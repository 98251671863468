import React, { Dispatch, useState } from 'react';
import { pick } from 'lodash';
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input } from 'components/ui/Input/Input';
import styles from 'components/Layout/AuthModal/LoginFormEmail/LoginFormEmail.module.scss';
import { Button } from "@kursk/components/ui/Button/Button";
import { notificationPush } from "@common/redux/slices/notification.slice";
import { useDispatch } from "react-redux";
import { useDispatchThunk } from "@common/redux/dispatchThunk";
import { loadToken, signIn } from "@common/redux/slices/auth.slice";
import { BackArrowIcon } from "@kursk/components/ui/icons";

export interface LoginFormProps {
  goToSignupForm: () => void;
  goToLostPasswordForm: () => void;
  goBack: () => void;
  setIsOpen: Dispatch<React.SetStateAction<boolean>>;
}

export const LoginFormEmail = ({ goToSignupForm, goToLostPasswordForm, setIsOpen, goBack }: LoginFormProps) => {
  const dispatch = useDispatch();
  const dispatchThunk = useDispatchThunk();
  const [signInError, setSignInError] = useState<boolean>(false);
  const { handleSubmit, register, formState: { errors, isDirty, isValid }, getValues } = useForm({
    mode: "onChange",
    resolver: yupResolver(Yup.object({
      email: Yup.string().email('Указан некорректный адрес электронной почты').required('Необходимо ввести почту'),
      password: Yup.string().required('Необходимо ввести пароль')
    })),
    defaultValues: {
      email: '',
      password: '',
    }
  });
  const onSubmit = async () => {
    const res = await dispatchThunk(signIn(pick(getValues(), ['email', 'password'])));
    if (!res.error) {
      setIsOpen(false);
      dispatch(notificationPush({ content: 'Вы успешно вошли в систему', type: 'success' }));
      await dispatchThunk(loadToken({}));
    }
    if (res.error) {
      setSignInError(true);
    }
  };

  return (
    <>
      <div className={styles.backArrow} onClick={goBack}>
        <BackArrowIcon/>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h1 className={styles.header}>Авторизация</h1>
        <label className={styles.label}>
          Email
        </label>
        <div className={styles.input_wrapper}>
          <Input
            {...register("email")}
            errors={errors}
          />
        </div>

        <label className={styles.label}>
          Пароль
        </label>
        <div className={styles.input_wrapper}>
          <Input
            type="password"
            {...register("password")}
            errors={errors}
          />
          {signInError && <div className={styles.text_error}>Неверная пара логин и пароль</div>}
        </div>
        <div className={styles.lost_password_block}>
          <a onClick={goToLostPasswordForm} className={styles.lost_password}>
            ЗАБЫЛИ ПАРОЛЬ?
          </a>
        </div>
        <Button
          text='ВОЙТИ'
          type='submit'
          className={styles.marginBottom}
          disabled={!isDirty || !isValid}
          buttonType='greenPrimary'
        />
        <Button
          text='ЗАРЕГИСТРИРОВАТЬСЯ'
          type="button"
          onClick={goToSignupForm}
          buttonType='ghostButton'
        />
      </form>
    </>
  );
};
