import React, { ReactElement } from 'react';
import { getComponentVersion } from "@kursk/utils/componentsTemplates";
import { Page } from "@kursk/components/seo/seo.types";
import { HeaderDefault, IProps } from './Header.default/Header.default';
import { HeaderTransparent } from './Header.transparent/Header.transparent';
import { HeaderBel } from './Header.bel/Header.bel';

const componentsHash = {
  default: HeaderDefault,
  bel: HeaderBel,
  transparent: HeaderTransparent,
}

interface HeaderComponentProps extends IProps {
  page?: Page,
}

export function HeaderComponent({ page, ...props }: HeaderComponentProps): ReactElement {

  const componentVersion = getComponentVersion({ componentType: 'header', page });
  const Component = componentsHash[componentVersion];

  return <Component {...props} />
}
