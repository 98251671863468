import React, { useEffect, useRef, useState } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import styles from './Notifications.module.scss';
import {
  INotification,
  notificationPush,
  notificationRemove,
  notificationSlice
} from "@common/redux/slices/notification.slice";
import { useSelectSlice } from "@common/redux/selectors/useSelectSlice";
import { useRouter } from "next/router";

interface IProps {
  notification: INotification;
}

const Notification = ({ notification }: IProps) => {

  const dispatch = useDispatch();
  const ref = useRef(null);

  const id = notification.id;
  const { content, type } = notification;

  const [width, setWidth] = useState('auto');
  const [closing, setClosing] = useState(false);

  const timerRef = { current: undefined };

  useEffect(() => {
    timerRef.current = setTimeout(onClose, 3000);
    return () => {
      clearInterval(timerRef.current);
      dispatch(notificationRemove({ id }));
    };
  }, []);

  const onClose = () => {
    clearTimeout(timerRef.current);

    setWidth(ref.current.clientWidth);
    setClosing(true);
  };

  const onAnimationEnd = () => dispatch(notificationRemove({ id }));

  return (
    <div
      onAnimationEnd={onAnimationEnd} style={{ width }} ref={ref} onClick={() => onClose()}
      className={classNames(styles.notification, {
        [styles.notification_closing]: closing,
        [styles.notification_error]: type === 'error',
        [styles.notification_success]: type === 'success',
        [styles.notification_warning]: type === 'warning',
        [styles.notification_default]: type === 'default',
      })}
    ><img className={styles.image} src={`/images/notifications/${type}.svg`}/>
      <LinesEllipsis
        text={content}
        maxLine={6}
        ellipsis='...'
        trimRight
        basedOn='letters'
        className={styles.content}
      />
    </div>
  );
};

export const Notifications = () => {
  const { notifications } = useSelectSlice(notificationSlice);


  const route = useRouter()
  const dispatch = useDispatch()

  const changeEmailSuccess: INotification = { content: 'Почта успешно изменена', type: 'success' }
  const changeEmailFail: INotification = { content: 'При смене почты возникли проблемы', type: 'error' }

  useEffect(() => {
    const { query } = route
    if (query.changeEmailStatus === 'success') {
      dispatch(notificationPush(changeEmailSuccess))
    }
    if (query.changeEmailStatus === 'fail') {
      dispatch(notificationPush(changeEmailFail))
    }
  }, [])


  return (
    <div className={classNames(styles.notifications, 'bvi-no-styles')}>
      {notifications.map((n) => <Notification key={n.id} notification={n}/>)}
    </div>
  );
};
