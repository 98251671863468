import React from 'react';
import ReactModal from 'react-modal';
import style from './Modal.module.scss';
import styles from "@kursk/components/Layout/RestorePasswordModal/RestorePasswordModal.module.scss";
import { CloseIcon, RoundCloseIcon } from "@kursk/components/ui/icons";
import classNames from "classnames";
import { useFixedBody } from "@common/hooks/useFixedBody";

interface BaseModalProps {
  isOpen: boolean,
  children: React.ReactNode,
  onRequestClose: () => void,
  isBackGroundWhite?: boolean,
  closeIcon?: boolean,
  classNameModal?: string,
  classNameWrapper?: string,
}

export const Modal = (props: BaseModalProps) => {
  const {
    isOpen,
    children,
    onRequestClose,
    classNameModal,
    classNameWrapper,
    isBackGroundWhite = true,
    closeIcon = true,
  } = props



  useFixedBody(isOpen)


  return (
    <ReactModal
      htmlOpenClassName="overflow-hidden"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={classNames(style.modal, classNameModal, { [style.modal__backgroundColor]: isBackGroundWhite })}
      overlayClassName={style.overlay}
      ariaHideApp={false}
    >
      {closeIcon?
        <div className={classNames(classNameWrapper ? classNameWrapper : styles.wrapper)}>
          <RoundCloseIcon onClick={onRequestClose} className={styles.close} />
          <CloseIcon
            width={24}
            height={24}
            className={classNames(styles.close, styles.close_mobile)}
            onClick={onRequestClose}
          />
          {children}
        </div> : <>{ children }</>
      }
    </ReactModal>
  );
}
