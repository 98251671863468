/* eslint-disable max-len */

export const SearchIcon = ({ width = 23, height = 23 }) => (
  <svg width={width} height={height} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M14.0475 14.4637C12.786 15.4269 11.2098 15.999 9.5 15.999C5.35786 15.999 2 12.6413 2 8.49948C2 4.35763 5.35786 1 9.5 1C13.6421 1 17 4.35763 17 8.49948C17 10.2106 16.4269 11.7879 15.4621 13.0499L21.7044 19.2917C22.095 19.6823 22.095 20.3154 21.7045 20.706C21.314 21.0965 20.6808 21.0965 20.2903 20.706L14.0475 14.4637ZM15 8.49948C15 11.5366 12.5377 13.999 9.5 13.999C6.4623 13.999 4 11.5366 4 8.49948C4 5.46233 6.4623 3 9.5 3C12.5377 3 15 5.46233 15 8.49948Z" fill="black"/>
  </svg>
);

export const ProfileIcon = ({ width = 21, height = 25 }) => (
  <svg width={width} height={height} viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5 10C12.2 10 13.56 8.65 13.56 7C13.56 5.35 12.2 4 10.5 4C8.8 4 7.44 5.35 7.44 7C7.44 8.65 8.8 10 10.5 10ZM10.5 12C7.7 12 5.44 9.76 5.44 7C5.44 4.24 7.7 2 10.5 2C13.3 2 15.56 4.24 15.56 7C15.56 9.76 13.3 12 10.5 12ZM3.5 20H17.5V18.67C17.5 16.92 15.19 15.11 10.5 15.11C5.81 15.11 3.5 16.92 3.5 18.67V20ZM10.5 13.11C17.16 13.11 19.5 16.44 19.5 18.67V22H1.5V18.67C1.5 16.44 3.84 13.11 10.5 13.11Z" fill="black"/>
  </svg>
);

export const CalendarIcon = ({ width = 18, height = 17 }) => (
  <svg width={width} height={height} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_31629_66350)">
      <path d="M14.25 1.41667H13.5V0.708333C13.5 0.520472 13.421 0.340304 13.2803 0.207466C13.1397 0.0746278 12.9489 0 12.75 0C12.5511 0 12.3603 0.0746278 12.2197 0.207466C12.079 0.340304 12 0.520472 12 0.708333V1.41667H6V0.708333C6 0.520472 5.92098 0.340304 5.78033 0.207466C5.63968 0.0746278 5.44891 0 5.25 0C5.05109 0 4.86032 0.0746278 4.71967 0.207466C4.57902 0.340304 4.5 0.520472 4.5 0.708333V1.41667H3.75C2.7558 1.41779 1.80267 1.79129 1.09966 2.45524C0.396661 3.11919 0.00119089 4.01937 0 4.95833L0 13.4583C0.00119089 14.3973 0.396661 15.2975 1.09966 15.9614C1.80267 16.6254 2.7558 16.9989 3.75 17H14.25C15.2442 16.9989 16.1973 16.6254 16.9003 15.9614C17.6033 15.2975 17.9988 14.3973 18 13.4583V4.95833C17.9988 4.01937 17.6033 3.11919 16.9003 2.45524C16.1973 1.79129 15.2442 1.41779 14.25 1.41667ZM1.5 4.95833C1.5 4.39475 1.73705 3.85425 2.15901 3.45573C2.58097 3.05722 3.15326 2.83333 3.75 2.83333H14.25C14.8467 2.83333 15.419 3.05722 15.841 3.45573C16.2629 3.85425 16.5 4.39475 16.5 4.95833V5.66667H1.5V4.95833ZM14.25 15.5833H3.75C3.15326 15.5833 2.58097 15.3595 2.15901 14.9609C1.73705 14.5624 1.5 14.0219 1.5 13.4583V7.08333H16.5V13.4583C16.5 14.0219 16.2629 14.5624 15.841 14.9609C15.419 15.3595 14.8467 15.5833 14.25 15.5833Z" fill="#005A98"/>
      <path d="M9 11.6875C9.62132 11.6875 10.125 11.2118 10.125 10.625C10.125 10.0382 9.62132 9.5625 9 9.5625C8.37868 9.5625 7.875 10.0382 7.875 10.625C7.875 11.2118 8.37868 11.6875 9 11.6875Z" fill="#005A98"/>
      <path d="M5.25 11.6875C5.87132 11.6875 6.375 11.2118 6.375 10.625C6.375 10.0382 5.87132 9.5625 5.25 9.5625C4.62868 9.5625 4.125 10.0382 4.125 10.625C4.125 11.2118 4.62868 11.6875 5.25 11.6875Z" fill="#005A98"/>
      <path d="M12.75 11.6875C13.3713 11.6875 13.875 11.2118 13.875 10.625C13.875 10.0382 13.3713 9.5625 12.75 9.5625C12.1287 9.5625 11.625 10.0382 11.625 10.625C11.625 11.2118 12.1287 11.6875 12.75 11.6875Z" fill="#005A98"/>
    </g>
    <defs>
      <clipPath id="clip0_31629_66350">
        <rect width="18" height="17" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);
