import React, { ReactElement } from "react";
import styles from './CookieAccept.module.scss';
import { Button } from "@kursk/components/ui/Button/Button";
import { regionContent } from "@kursk/content/regionContent";

interface IProps {
  setAcceptCookie: () => void;
}

export const CookieAccept = ({ setAcceptCookie }: IProps): ReactElement => (
  <div className={styles.cookies}>
    <div className={styles.cookieWrap}>
      <div className={styles.cookieContent}>
        <div className={styles.cookieTitle}>
          Мы используем файлы cookie, чтобы предоставить вам больше возможностей при использовании сайта.
        </div>
        <div className={styles.cookieSub}>
          Продолжая просмотр страниц сайта, вы даете свое согласие на использование cookie.
          <span className={styles.cookieReedMore}>
            <a href={regionContent.agreementLink} rel={'noopener noreferrer'} target={'_blank'}>Подробнее.</a>
          </span>
        </div>
      </div>
      <div className={styles.cookieAction} onClick={setAcceptCookie}>
        <Button text='Понятно, спасибо'/>
      </div>
    </div>
  </div>
);
