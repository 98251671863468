import styles from './RadioButton.module.scss';
import React from 'react';

interface IInputProps {
  value: string;
  onChange: any;
  checked: boolean;
  title: string;
}

export const RadioButton = React.forwardRef(({ value, onChange, checked, title }: IInputProps, ref: any) => {

  return (
    <label className={styles.radio}>
      <input
        ref={ref}
        className={styles.radio_button}
        type="radio"
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <span className={styles.radio_circle}/>
      <span>{title}</span>
    </label>
  )
});
