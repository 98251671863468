import React, { ReactElement, ReactNode } from 'react';

export interface ViewProps {
  activePanel: string;
  children: ReactElement<ViewPanelProps> | ReactElement<ViewPanelProps>[];
}

export const View = ({ activePanel, children }: ViewProps) => {
  const panels: ReactElement<ViewPanelProps>[] = [].concat(children);

  return panels.find(panel => panel.props.panelId === activePanel);
};

export interface ViewPanelProps {
  panelId: string;
  children: ReactNode | ReactElement | ReactElement[];
}

// eslint-disable-next-line no-unused-vars
export const ViewPanel = ({ children }: ViewPanelProps) => (
  <>
    {children}
  </>
);
