import { ISproject } from "@common/redux/slices/sprojects.slice";
import { IMenuItem } from "@kursk/components/Layout/Layout";

type extraParams = {
  sprojects: ISproject[];
}

export const prepareMenuLinks = (links: IMenuItem[], { sprojects }: extraParams): IMenuItem[] => {
  const sprojectsLinks = sprojects.map((sp) => ({ title: sp.name, target: '_blank', to: `/s/${sp.path}` }));
  const extraLinkSources = { sprojects: sprojectsLinks };
  return links.map(item => prepareMenuItem(item, extraLinkSources)).filter(i => !!i);
}

const prepareMenuItem = (item, extraLinkSources) => {
  const result = JSON.parse(JSON.stringify(item));
  if (result.links) {
    result.links = item.links.map(subItem => prepareMenuItem(subItem, extraLinkSources)).filter(i => !!i);
    return result;
  }
  if (result.linksSource) {

    if (!extraLinkSources[result.linksSource]) {
      throw new Error(`Unkown link source - ${JSON.stringify(result.linksSource)}`);
    }
    /* Если ссылок в доп.ресурсах нет (например, нет ни одного опубликованного спец.проекта) -
     * скрываем соотв. пункт из меню
     */
    if (extraLinkSources[result.linksSource].length === 0) return null;
    result.links = extraLinkSources[item.linksSource];
  }
  return result;
}
