import { regionContent } from '@kursk/content/regionContent';
import { Page } from "@kursk/components/seo/seo.types";

interface IProps {
  componentType: string,
  page?: Page,
}

export const getComponentVersion = ({ componentType, page }: IProps) => {
  return regionContent.componentsConfig?.pages?.[page]?.[componentType]
    || regionContent.componentsConfig?.[componentType]
    || 'default';
};
