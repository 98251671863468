import { ArrowDownIcon } from '@kursk/components/ui/icons';
import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import styles from './ScrollButton.module.scss';
import { themeValue } from "@kursk/themes";
import { BelDownArrowIcon } from "@kursk/components/ui/icons.bel";

const ScrollButton = () => {

  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 250){
      setVisible(true)
    }
    else if (scrolled <= 250){
      setVisible(false)
    }
  };

  const scrollToTop = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisible);

    return () => {
      window.removeEventListener('scroll', toggleVisible);
    }
  }, [])

  return (
    <button className={classNames(styles.button, !visible && styles.hidden)} onClick={scrollToTop}>
      {themeValue({
        bel: <BelDownArrowIcon/>,
        default: <ArrowDownIcon width={20} height={8} />,
      })}
    </button>
  );
}

export default ScrollButton;
