const subscribesHash = {};

export const subscribePostMessage = (id, callback) => {
  if (subscribesHash[id] !== undefined) return false;

  subscribesHash[id] = (event) => {
    if (event.data && event.data.eventName && event.data.eventName === id) {
      callback(event.data);
    }
  }

  window.addEventListener('message', subscribesHash[id]);
}

export const unSubscribePostMessage = (id) => {
  if (!subscribesHash[id]) return;
  window.removeEventListener('message', subscribesHash[id]);
  subscribesHash[id] = undefined;
}
