import Script from 'next/script';
import React from 'react';

interface UnisenderNewsPopupProps {
  id: string
}

export const UnisenderNewsPopup = ({ id }: UnisenderNewsPopupProps) => {

  if (!id) {
    return <></>;
  }

  return (
    <Script
      src={`//popup-static.unisender.com/service/loader.js?c=${id}`}
      id="unisender-popup-forms"
      strategy='lazyOnload'>
    </Script>
  )
}
