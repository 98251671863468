import { useSelector } from 'react-redux';
import { Slice } from "@reduxjs/toolkit/src/createSlice";

export function useSelectSlice<T>(slice: Slice<T, any>): T {
  return useSelector(state => {
    if (state[slice.name] === undefined) {
      throw new Error(`Slice ${slice.name} is not in the store`);
    }
    return state[slice.name];
  });
}
