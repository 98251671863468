import React, { useState, ReactElement } from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import { IMenuItem } from '../../Layout/Layout';
import { ArrowDownIcon } from '../icons';
import styles from './MenuDropdownButton.module.scss';
import { useOrientations } from "@common/hooks/useOrientation";

interface IProps {
  className?: string;
  title: string;
  items: IMenuItem[];
  setIsBurgerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  link?: string;
}

export function MenuDropdownButton({ title, items, className, setIsBurgerOpen, link }: IProps): ReactElement {
  const [isOpen, toggleIsOpen] = useState(false);

  const orientation = useOrientations();

  const toggle = () => {
    if (orientation !== 'desktop') {
      toggleIsOpen(!isOpen);
    }
  };

  const MenuWrapper = ({ children }) => {
    if (orientation === 'desktop' && link) {
      return <Link href={link}>
        <a href={link} className={styles.desktopLink}>
          {children}
        </a>
      </Link>
    }
    return <>{children}</>
  };

  return (
    <div
      className={classNames(
        isOpen && styles.open,
        styles.button,
        className,
        orientation !== 'desktop' && styles.mobileDropdown
      )}
      onClick={toggle}>
      <MenuWrapper>
        <div className={classNames(isOpen && styles.title_open, styles.title)}>
          <div className={styles.text}>
            {title}
            <ArrowDownIcon/>
          </div>
        </div>
      </MenuWrapper>
      <div
        className={classNames(
          !isOpen && styles.hidden,
          orientation !== 'desktop' && styles.mobileDropdownList,
          isOpen && styles.open, styles.dropdownList
        )}>
        <ul>
          {items.map((item) =>
            item.target ? (
              <a
                className={styles.listItem}
                href={item.to}
                target={item.target}
              >{item.title}</a>
            ) : (
              <Link href={item.to}>
                <a onClick={() => setIsBurgerOpen(false)} className={styles.listItem}>
                  {item.title}
                </a>
              </Link>
            )
          )}
        </ul>
      </div>
    </div>
  )
}
