import React, { TextareaHTMLAttributes } from "react";
import styles from './Textarea.module.scss';
import classNames from "classnames";
import { get } from 'lodash';

interface IInputProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: string;
  errors?: any;
  rows?: number;
  className?: string;
  areaClassName?: string;
}

export const Textarea = React.forwardRef(({
  error,
  errors,
  className,
  areaClassName,
  rows = 15,
  ...props
}: IInputProps, ref: any) => {
  const errorMessage = error || get(errors, `${props.name}.message`) || false;
  const title = props.title || errorMessage || '';
  return (
    <div className={classNames([styles.wrapper, className])}>
      <textarea
        {...props}
        rows={rows}
        ref={ref}
        title={title}
        className={classNames([errorMessage && styles.area__error, styles.area, areaClassName && areaClassName])}
      />
      {errorMessage && <div className={styles.errorText}>{errorMessage}</div>}
    </div>);
});
