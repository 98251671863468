import React, { ReactElement } from 'react';
import classNames from "classnames";
import style from './BaseSubscribeForm.module.scss';
import { Input } from "../Input/Input";
import { useForm } from "react-hook-form";
import { SubscribeButton } from "../SubscribeButton/SubscribeButton";
import { ModalLogo } from "./ModalLogo/ModalLogo";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Checkbox } from "@kursk/components/ui/Checkbox/Checkbox";
import { notificationPush } from "@common/redux/slices/notification.slice";
import { useDispatch } from "react-redux";
import { regionContent } from "@kursk/content/regionContent";
import { themeValue } from "@kursk/themes";

interface IProps {
  subscriptionSuccess: boolean;
  setSubscriptionSuccess: (email: string) => Promise<boolean>;
  title?: string;
  subTitle?: string;
  successText?: string;
  successDisplayText?: string;
  successBlock?: JSX.Element;
  inModal?: boolean;
}

export const BaseSubscribeForm = ({
  title = regionContent.subscribeForm.title,
  subTitle = 'Подпишитесь на еженедельную рассылку',
  successText = 'Вы успешно подписались на еженедельную рассылку!',
  successDisplayText = 'Вы подписаны на еженедельную рассылку о мероприятиях и событиях',
  successBlock = null,
  subscriptionSuccess,
  setSubscriptionSuccess,
  inModal = false,
}: IProps): ReactElement => {

  const dispatch = useDispatch();

  const defaultValues = {
    email: '',
    agree: false,
  }

  const { register, handleSubmit, formState, getValues, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(yup.object({
      email: yup.string().required('Введите адрес электронной почты')
        .email('Адрес электронной почты введен некорректно'),
      agree: yup.boolean().test({
        test: agree => !!agree,
        message: 'Необходимо подтвердить согласие с условиями пользовательского соглашения'
      }),
    })),
    defaultValues: defaultValues
  });

  const onSubmit = async () => {
    // если пользователь еще не подписан
    if (!subscriptionSuccess) {
      const res = await setSubscriptionSuccess(getValues().email);
      if (res) {
        dispatch(notificationPush({ content: successText, type: 'success' }));
      }
      reset(defaultValues);
    }
  }

  if (!!successBlock && subscriptionSuccess) {
    return successBlock;
  }

  return (
    <div className={classNames(inModal && style.modal)}>
      <div className={style.wrapper}>
        {inModal && <ModalLogo />}
        <div className={style.form}>
          <div className={style.title}>
            {title}
          </div>
          <div className={style.subTitle}>
            {subTitle}
          </div>
          {(!subscriptionSuccess) && (
            <div className={style.inputWrapper}>
              <label className={style.emailLabel}>Ваша электронная почта</label>
              <div className={style.inputRow}>
                <Input {...register('email')} errors={formState.errors} className={style.input}/>
                <SubscribeButton className={style.desktopButton} onClick={handleSubmit(onSubmit)}>
                  Подписаться
                </SubscribeButton>
              </div>
              <div className={style.checkboxWrapper}>
                <Checkbox {...register('agree')} errors={formState.errors}>
                  <span className={style.agreementText}>Я подтверждаю свое согласие с условиями
                    <a
                      className={'withLine'}
                      target="_blank"
                      href={regionContent.agreementLink}
                      rel="noreferrer"
                    > пользовательского соглашения</a>
                  </span>
                </Checkbox>
              </div>
              <SubscribeButton className={style.mobileButton} onClick={handleSubmit(onSubmit)}>
                Подписаться
              </SubscribeButton>
            </div>)}
          {(subscriptionSuccess) && (<div className={style.subscribed}>
            {successDisplayText}
          </div>)}
        </div>
        {!inModal && themeValue({
          bel: <div className={style.logo}/>,
          astrakhan: (
            <div className={style.logo}>
              <img src="/images/big-logo.png" />
            </div>
          ),
          default: (
            <div className={style.logo}>
              <img src="/images/big-logo.svg"/>
            </div>
          )
        })}
      </div>
    </div>
  );
};
