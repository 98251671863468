import React, { ReactElement, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import style from "./Header.bel.module.scss";
import { BurgerMenuIcon } from "@kursk/components/ui/icons";
import { ProfileIcon, SearchIcon } from "@kursk/components/ui/icons.bel";
import Link from "next/link";
import { regionContent } from "@kursk/content/regionContent";
import { getImageUrl } from "@common/utils/image";
import { useHideHeader } from "@common/hooks/useHideHeader";
import { useSelectSlice } from "@common/redux/selectors/useSelectSlice";
import { IMenuItem } from "@kursk/components/Layout/Layout";
import { authSlice } from "@common/redux/slices/auth.slice";
import { AuthModal } from "@kursk/components/Layout/AuthModal/AuthModal";
import { LogoutModal } from "@kursk/components/Layout/logoutModal/LogoutModal";
import { SearchModal } from "@kursk/components/Layout/SearchModal/SearchModal";

interface IProps {
  isBurgerOpen: boolean;
  mainMenu: IMenuItem[];
  setIsBurgerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  renderMenuLink: (e: IMenuItem) => ReactElement;
  showAuth?: boolean;
}

const profileButton: IMenuItem[] = [
  { title: 'Профиль', to: '/profile' },
];

const showDobroLogo = false;

export function HeaderBel(
  { setIsBurgerOpen, isBurgerOpen, renderMenuLink, mainMenu, showAuth = false }: IProps
): ReactElement {
  const headerStatus = useHideHeader();
  const { isAuth, user } = useSelectSlice(authSlice);
  const profileRef = useRef(null);
  const [isOpenProfile, setIsOpenProfile] = useState(false);
  const [isOpenSearchModal, setIsOpenSearchModal] = useState(false);
  const [isOpenAuthModal, setIsOpenAuthModal] = useState(showAuth);
  const [isLogoutModal, setIsLogoutModal] = useState(false);

  const toggleProfile = (e) => {
    e.stopPropagation();
    setIsOpenProfile(!isOpenProfile);
  };
  const showAuthModal = () => {
    setIsOpenAuthModal(true);
  }
  const showSearchModal = () => {
    setIsOpenSearchModal(true);
  }
  const showLogoutModal = () => {
    setIsLogoutModal(true);
  }

  const hideMenu = () => setIsOpenProfile(false);

  useEffect(() => {
    isBurgerOpen === true ? document.body.classList.add('no-scroll') : document.body.classList.remove('no-scroll');
  }, [isBurgerOpen, isAuth]);

  useEffect(() => {
    document.body.addEventListener('click', hideMenu);
    return () => document.body.removeEventListener('click', hideMenu);
  }, []);

  return (
    <>
      <AuthModal
        isOpen={isOpenAuthModal}
        setIsOpen={setIsOpenAuthModal}
      />
      <LogoutModal
        isOpen={isLogoutModal}
        setIsOpen={setIsLogoutModal}
      />
      <SearchModal
        isOpen={isOpenSearchModal}
        setIsOpen={setIsOpenSearchModal}
      />
      <div className={classNames([
        style.header,
        isBurgerOpen && style.header_fixed,
        headerStatus === 'prepare' && style.header_prepare,
        headerStatus === 'hide' && style.header_hide,
        headerStatus === 'fixed' && style.header_view
      ])}>
        <div className={style.header__logoWrapper}>
          <button
            onClick={() => setIsBurgerOpen(!isBurgerOpen)}
            className={classNames([style.header__burgerMenuButton, isBurgerOpen && style.header__menuOpen])}>
            <BurgerMenuIcon height={33}/>
          </button>
          <Link href={'/'} passHref>
            <a className={style.header__logoLink} title={`${regionContent.title}`}>
              <div className={classNames([style.header__logo, showDobroLogo && style.header__logo_dobro])}/>
            </a>
          </Link>
        </div>
        <div className={style.header__menu}>
          {mainMenu.map((item) => renderMenuLink(item))}
        </div>
        <div className={style.header__buttons}>
          <button className={style.header__search} onClick={showSearchModal}><SearchIcon/></button>
          {!isAuth && <button className={style.header__profile} onClick={showAuthModal}>
            <ProfileIcon/>
          </button>}
          {isAuth && (
            <>
              <button className={style.header__profileAuth} onClick={toggleProfile} ref={profileRef}>
                {user?.avatar ? (
                  <span className={style.header__profileAuth__avatar}>
                    <div className={style.header__profileAuth__avatar__overlay}/>
                    <img src={getImageUrl(user.avatar, 32, 32)} alt={user.avatar.realName}/>
                  </span>
                ) : (
                  <span className={style.header__profileAuth__label}>
                    {user?.name?.split('').shift()}
                  </span>
                )}
              </button>
              <div className={classNames(
                !isOpenProfile && style.hidden,
                isOpenProfile && style.open,
                style.dropdownList,
                style.dropdownList__profile,
              )}>
                <ul>
                  {profileButton.map((item) =>
                    <li key={item.to} className={style.listItem}><Link href={item.to}>{item.title}</Link></li>
                  )}
                  <li className={style.listItem}>
                    <button onClick={showLogoutModal}>Выйти</button>
                  </li>
                </ul>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}
