import React, { useState, useEffect } from 'react';
import { regionContent } from '@kursk/content/regionContent';
import styles from './Maintenance.module.scss';

const Maintenance = () => {
  const [visible, setVisible] = useState(false);
  const isEnabled = regionContent.maintenanceBanner && regionContent.maintenanceBanner.enabled;

  const onHide = () => {
    sessionStorage.setItem('maintenance_hidden', 'true');
    setVisible(false);
  };

  useEffect(() => {
    setVisible(!sessionStorage.getItem('maintenance_hidden'));
  }, []);

  return isEnabled && visible ? (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.text}>
          {regionContent.maintenanceBanner.text}
        </div>
        <div className={styles.action} onClick={onHide}>скрыть</div>
      </div>
    </div>
  ) : null;
}

export default Maintenance;
