import { forEach } from "lodash";
import { IUser } from "../redux/types/common";

interface EsiaProfileFieldsForWidget {
  esiaName: string;
  esiaLastName: string;
  esiaMiddleName: string;
  esiaEmail: string;
  esiaPhone: string;
  esiaId: number;
}

export const getEsiaProfileFields = (user: IUser): EsiaProfileFieldsForWidget => {
  const { socials: { esia } } = user;
  return {
    esiaName: esia.user.firstName,
    esiaLastName: esia.user.lastName,
    esiaMiddleName: esia.user.middleName,
    esiaEmail: esia.email,
    esiaPhone: esia.mobile,
    esiaId: esia.oid,
  };
}

export const esiaLogin = (additionalUrlParams = null, withLogout = false) => {
  const url = new URL(window.location.href);
  if (additionalUrlParams) {
    forEach(additionalUrlParams, (value, key) => {
      url.searchParams.set(key, value)
    })
  }
  const returnUrl = encodeURIComponent(url.toString());
  const loginUrl = withLogout ?
    `/api/1.0/oauth/logout/esia?returnUrl=${returnUrl}` :
    `/api/1.0/oauth/login/esia?returnUrl=${returnUrl}`;
  window.location.replace(loginUrl);
}
