import React, { Dispatch } from 'react';
import classNames from 'classnames';
import { Modal } from '../../ui/Modal/Modal';
import { CloseIcon, RoundCloseIcon } from "@kursk/components/ui/icons";
import styles from './WriteToUsModal.module.scss';
import { WriteToUsForm } from "@kursk/components/ui/WriteToUsForm/WriteToUsForm";

export interface WriteToUsModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<React.SetStateAction<boolean>>;
}

export const WriteToUsModal = ({ isOpen, setIsOpen }: WriteToUsModalProps) => {
  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <div className={styles.wrapper}>
        <RoundCloseIcon onClick={onClose} className={styles.close} />
        <CloseIcon
          width={24}
          height={24}
          className={classNames(styles.close, styles.close_mobile)}
          onClick={onClose}
        />
        <div>
          <WriteToUsForm inModal closeModal={setIsOpen}/>
        </div>
      </div>
    </Modal>
  )
}
