import React from 'react';
import { Input } from "@kursk/components/ui/Input/Input";
import { Button } from "@kursk/components/ui/Button/Button";
import { Modal } from '../../ui/Modal/Modal';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import styles from "./RestorePasswordModal.module.scss";
import { restorePasswordFromLink } from "@common/redux/slices/auth.slice";
import { useDispatchThunk } from "@common/redux/dispatchThunk";
import { notificationPush } from "@common/redux/slices/notification.slice";
import { useDispatch } from "react-redux";

export interface AuthModalProps {
  isOpen: boolean;
  setIsOpen: (boolean) => void,
  restoreToken: string,
}

export const RestorePasswordModal = ({ isOpen, setIsOpen, restoreToken }: AuthModalProps) => {
  const dispatch = useDispatch();
  const dispatchThunk = useDispatchThunk();
  const { handleSubmit, register, formState: { errors, isDirty, isValid }, getValues } = useForm({
    mode: "onChange",
    resolver: yupResolver(Yup.object({
      password: Yup.string().required('Необходимо ввести пароль'),
      repeatPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Пароли должны совпадать'),
    })),
    defaultValues: {
      password: '',
      repeatPassword: '',
    }
  });
  const onClose = () => {
    setIsOpen(false);
  };
  const onSubmit = async () => {
    const res = await dispatchThunk(restorePasswordFromLink({ password: getValues().password, token: restoreToken }));
    if (!res.error) {
      dispatch(notificationPush({ content: 'Вы успешно сменили пароль', type: 'success' }));
      setIsOpen(false);
    }
    return
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h1 className={styles.header}>ВОССТАНОВЛЕНИЕ ПАРОЛЯ</h1>

        <label className={styles.label}>
          Пароль
        </label>
        <div className={styles.input_wrapper}>
          <Input
            type="password"
            {...register("password")}
            errors={errors}
          />
        </div>

        <label className={styles.label}>
          Подтверждение пароля
        </label>
        <div className={styles.input_wrapper}>
          <Input
            type="password"
            {...register("repeatPassword")}
            errors={errors}
          />
        </div>
        <Button
          text={'СОХРАНИТЬ И ВОЙТИ'}
          type="submit"
          disabled={!isDirty || !isValid}
        />
      </form>
    </Modal>
  );
}
