import React, { InputHTMLAttributes } from "react";
import { get } from "lodash";
import styles from './Checkbox.module.scss';
import classNames from "classnames";

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  errors?: any;

  type?: string;
}

export const Checkbox = React.forwardRef(({
  error,
  errors,
  children,
  type = 'checkbox',
  ...props
}: IProps, ref: any) => {
  const errorMessage = error || get(errors, `${props.name}.message`) || false;
  return (
    <>
      <label title={errorMessage || ''} className={classNames([styles.checkbox])}>
        <input ref={ref} type={type} {...props}/>
        <span className={classNames(styles.checkmark,errorMessage && styles.error)} />
        {children && (
          <div className={classNames(styles.content)}>{children}</div>
        )}
      </label>
      {errorMessage && <div className={styles.error_text}>{errorMessage}</div>}
    </>
  );
});
