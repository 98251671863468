import { EmailIcon, EsiaAuthButtonIcon, EsiaIcon, PhoneIcon, VkIcon } from '@kursk/components/ui/icons';
import classNames from 'classnames';
import { Button, ButtonTypeEnum } from 'components/ui/Button/Button';
import styles from './IconButton.module.scss';

interface IconButtonProps {
  onClick: () => void;
  text?: string;
  className?: string;
  icon: 'vk' | 'email' | 'phone' | 'esia';
  withoutGap?: boolean;
  buttonType?: ButtonTypeEnum;
}

const iconData = {
  vk: {
    icon: VkIcon,
    extraClasses: [styles.white],
  },
  email: {
    icon: EmailIcon,
    extraClasses: [styles.labelIcon],
  },
  phone: {
    icon: PhoneIcon,
    extraClasses: [styles.labelIcon],
  },
  esia: {
    icon: EsiaIcon,
    extraClasses: [styles.esia],
  },
}

export const IconButton = ({ onClick, text, className, icon, withoutGap, buttonType }: IconButtonProps) => {
  const IconComponent = iconData[icon].icon;
  const extraClasses = iconData[icon].extraClasses;
  return (
    <div className={classNames(styles.wrapper, withoutGap && styles.withoutGap)}>
      <div className={classNames(styles.icon, ...extraClasses)}>
        <IconComponent width={18} height={13} />
      </div>
      <Button
        buttonType={buttonType || 'greenPrimary'}
        type="submit"
        className={classNames(
          styles.button,
          className
        )}
        onClick={onClick}
        text={text}
      >
        {icon === 'esia' && <EsiaAuthButtonIcon/>}
      </Button>
    </div>
  )
}
