import React, { ReactElement } from 'react';
import styles from './ModalLogo.module.scss';

export const ModalLogo = (): ReactElement => {

  return (
    <div className={styles.logoWrapper}>
      <div className={styles.logo}>
        <img src="/images/subscribe-modal-logo.svg"/>
      </div>
    </div>
  );
};
