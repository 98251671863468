import React, { Dispatch } from 'react';
import { Modal } from '../../ui/Modal/Modal';
import { RoundCloseIcon } from "@kursk/components/ui/icons";
import styles from './LogoutModal.module.scss';
import { useDispatchThunk } from "@common/redux/dispatchThunk";
import { logout } from "@common/redux/slices/auth.slice";
import { push } from "connected-next-router";
import { useDispatch } from "react-redux";
import { Button } from "@kursk/components/ui/Button/Button";

export interface WriteToUsModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<React.SetStateAction<boolean>>;
}

export const LogoutModal = ({ isOpen, setIsOpen }: WriteToUsModalProps) => {
  const dispatchThunk = useDispatchThunk();
  const dispatch = useDispatch();
  const onClose = () => {
    setIsOpen(false);
  };
  const onAccept = async () => {
    await dispatchThunk(logout());
    setIsOpen(false);
    await dispatch(push({ pathname: '/' }));
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} classNameWrapper={styles.modalWrapper}>
      <div className={styles.wrapper}>
        <RoundCloseIcon onClick={onClose} className={styles.close} />
        <div className={styles.wrapperInner}>
          <div className={styles.title}>Вы действительно хотите выйти?</div>
          <div className={styles.actions}>
            <Button text='Нет' onClick={onClose} buttonType='ghostButton'/>
            <Button text='Да' onClick={onAccept} buttonType='greenPrimary'/>
          </div>
        </div>
      </div>
    </Modal>
  )
}
