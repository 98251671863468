import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { getCookie, setCookie } from "cookies-next";
import { throttle } from 'lodash';
import classNames from "classnames";
import { getConfig } from "@root/config/config";
import { Modal } from "@kursk/components/ui/Modal/Modal";
import { RoundCloseIcon } from "@kursk/components/ui/icons";
import { BaseSubscribeForm } from "@kursk/components/ui/BaseSubscribeForm/BaseSubscribeForm";
import { ModalSuccessForm } from "@kursk/components/ui/BaseSubscribeForm/ModalSuccessForm/ModalSuccessForm";
import { useSelectSlice } from "@common/redux/selectors/useSelectSlice";
import {
  loadSubscribeStatus,
  subscribeThunk,
  subscriptionFormSlice
} from "@common/redux/slices/subscriptionForm.slice";
import { useDispatchThunk } from "@common/redux/dispatchThunk";
import { useOrientations } from "@common/hooks/useOrientation";
import styles from "./SubscribeModal.module.scss";

const COOKIE_NAME = 'subscribe_modal_showed';

export const SubscribeModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [canShowModal, setCanShowModal] = useState(false);
  const showModalTimeout = useRef(null);
  const orientation = useOrientations();

  const subscription = useSelectSlice(subscriptionFormSlice);
  const dispatch = useDispatch();
  const dispatchThunk = useDispatchThunk();

  const successBlock = (): JSX.Element => (
    <ModalSuccessForm onButtonClick={() => setIsOpen(false)} />
  );

  const onClose = () => setIsOpen(false);

  const handleSubmit = async (email: string) => {
    const res = await dispatchThunk(subscribeThunk({ email, from: 'popup' }));
    if (!res.error) {
      return true;
    }
  }

  const showModal = () => {
    setIsOpen(true);
    setCookie(COOKIE_NAME, true);
    setCanShowModal(false);
    clearTimeout(showModalTimeout.current);
  }

  const scrollHandler = throttle(() => {
    if (orientation === 'desktop') {
      if (document.documentElement.scrollTop >
        document.documentElement.scrollHeight / 2 - document.documentElement.clientHeight / 2
      ) {
        showModal();
      }
    } else {
      if (document.documentElement.scrollTop > document.documentElement.clientHeight * 1.5) {
        showModal();
      }
    }
  }, 500)

  React.useEffect(() => {
    dispatch(loadSubscribeStatus({}))
  }, [subscription.status]);

  useEffect(() => {
    if (canShowModal && !subscription.status) {
      showModalTimeout.current = setTimeout(() => {
        showModal();
      }, 20000);

      document.addEventListener('scroll', scrollHandler);
    }

    return () => {
      clearTimeout(showModalTimeout.current);
      document.removeEventListener('scroll', scrollHandler);
    }
  }, [canShowModal, orientation, subscription.status])

  useEffect(() => {
    setCanShowModal(getConfig().subscribeModalDisabled[orientation] ? false : !getCookie(COOKIE_NAME))
  }, [orientation])

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} classNameModal={styles.modal} classNameWrapper={styles.wrapper}>
      <div>
        <RoundCloseIcon onClick={onClose} className={classNames(styles.close, styles.close_mobile)} />
        <BaseSubscribeForm
          inModal
          subscriptionSuccess={subscription.status}
          successBlock={successBlock()}
          setSubscriptionSuccess={handleSubmit}
        />
      </div>
    </Modal>
  )
}
