import React, { Dispatch, useEffect, useState } from 'react';
import { Modal } from '../../ui/Modal/Modal';
import { View, ViewPanel } from "@kursk/components/Layout/AuthModal/View/View";
import { LoginFormEmail } from "@kursk/components/Layout/AuthModal/LoginFormEmail/LoginFormEmail";
import { OptionsForm } from "@kursk/components/Layout/AuthModal/OptionsForm/OptionsForm";
import { SignUpForm } from "@kursk/components/Layout/AuthModal/SignUpForm/SignUpForm";
import { LostPasswordForm } from "@kursk/components/Layout/AuthModal/LostPasswordForm/LostPasswordForm";
import { LoginFormPhone } from "@kursk/components/Layout/AuthModal/LoginFormPhone/LoginFormPhone";
import { AuthModalPanels } from "@common/redux/types/auth";
import { clearSignInResponseErrors } from "@common/redux/slices/auth.slice";
import { useDispatchThunk } from "@common/redux/dispatchThunk";

export interface AuthModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<React.SetStateAction<boolean>>;
}

export const AuthModal = ({ isOpen, setIsOpen }: AuthModalProps) => {
  const dispatchThunk = useDispatchThunk();
  const [activePanel, setActivePanel] = useState<AuthModalPanels>('options');

  const onClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (!isOpen) setActivePanel('options');
    dispatchThunk(clearSignInResponseErrors({}));
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <View activePanel={activePanel}>

        <ViewPanel panelId="options">
          <OptionsForm
            goToLoginForm={() => setActivePanel('loginEmail')}
            goToSignupForm={() => setActivePanel('signupEmail')}
            goToLoginPhone={() => setActivePanel('loginPhone')}
            goToLostPasswordForm={() => setActivePanel('lost_password')}
          />
        </ViewPanel>

        <ViewPanel panelId="loginEmail">
          <LoginFormEmail
            goToSignupForm={() => setActivePanel('signupEmail')}
            goBack={() => setActivePanel('options')}
            goToLostPasswordForm={() => setActivePanel('lost_password')}
            setIsOpen={setIsOpen}
          />
        </ViewPanel>

        <ViewPanel panelId="loginPhone">
          <LoginFormPhone
            goBack={() => setActivePanel('options')}
            goToSignupForm={() => setActivePanel('signupPhone')}
            setIsOpen={setIsOpen}
          />
        </ViewPanel>

        <ViewPanel panelId="signupEmail">
          <SignUpForm
            goToLoginForm={() => setActivePanel('options')}
            setIsOpen={setIsOpen}
            goBack={() => setActivePanel('options')}
            type={'email'}
          />
        </ViewPanel>

        <ViewPanel panelId="signupPhone">
          <SignUpForm
            goToLoginForm={() => setActivePanel('options')}
            setIsOpen={setIsOpen}
            goBack={() => setActivePanel('options')}
            type={"phone"}
          />
        </ViewPanel>

        <ViewPanel panelId="lost_password">
          <LostPasswordForm
            goToSignupForm={() => setActivePanel('signupEmail')}
            goBack={() => setActivePanel('loginEmail')}
          />
        </ViewPanel>
      </View>
    </Modal>
  )
}
