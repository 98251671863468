import React, { ReactElement, useEffect, useState } from 'react';
import { seoSendRegistrationGoal } from '@kursk/components/seo/utils';
import styles from "./Confirm.module.scss";

interface Props {
  title: string;
  description: string;
  onSubmit: () => void;
  resendText: string;
  countText: string;
  children?: ReactElement;
  footer?: ReactElement;
  withRegistrationGoal?: boolean;
}

export const ConfirmView = ({
  title,
  description,
  onSubmit,
  resendText,
  countText,
  children,
  footer,
  withRegistrationGoal,
}: Props): ReactElement => {
  const [seconds, setSeconds] = useState<number>(59);
  const [isTimerActive, setIsTimerActive] = useState<boolean>(true);

  const onResend = () => {
    onSubmit();
    setSeconds(59);
    setIsTimerActive(true);
  }

  useEffect(() => {
    let interval = null;
    if (isTimerActive && seconds !== 0) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds - 1);
      }, 1000);
    } else if (!isTimerActive && seconds !== 0) {
      clearInterval(interval);
    } else if (seconds === 0){
      setIsTimerActive(false);
    }
    return () => clearInterval(interval);
  }, [isTimerActive]);

  useEffect(() => {
    if (withRegistrationGoal) {
      seoSendRegistrationGoal();
    }
  }, [])

  return (
    <div>
      <h1 className={styles.header}>{title}</h1>
      <div className={styles.description}>
        {description}
      </div>
      {children && (
        <div>
          {children}
        </div>
      )}
      {seconds > 0 ?
        <div className={styles.counter}>
          {`${countText} 00:${seconds<10 ? 0 : ''}${seconds}`}
        </div>
        :
        <a className={styles.resend} onClick={onResend}>
          {resendText}
        </a>
      }
      {footer && (
        <div>
          {footer}
        </div>
      )}
    </div>
  );
};
