/* eslint-disable max-len */

export const SearchIcon = ({ width = 23, height = 23 }) => (
  <svg width={width} height={height} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M15.4985 9.14225C15.4238 12.6188 12.6196 15.4233 9.14322 15.4985C9.09561 15.4995 9.04787 15.5 9 15.5C5.41015 15.5 2.5 12.5899 2.5 9C2.5 5.41015 5.41015 2.5 9 2.5C12.5899 2.5 15.5 5.41015 15.5 9C15.5 9.04754 15.4995 9.09496 15.4985 9.14225ZM15.676 14.2618C16.8183 12.8145 17.5 10.9869 17.5 9C17.5 4.30558 13.6944 0.5 9 0.5C4.30558 0.5 0.5 4.30558 0.5 9C0.5 13.6944 4.30558 17.5 9 17.5C10.7604 17.5 12.3958 16.9648 13.7524 16.0483C13.9269 15.9304 14.0968 15.8062 14.2618 15.676L20.7929 22.2071C21.1834 22.5976 21.8166 22.5976 22.2071 22.2071C22.5976 21.8166 22.5976 21.1834 22.2071 20.7929L15.676 14.2618Z" fill="black"/>
  </svg>

);

export const ProfileIcon = ({ width = 21, height = 25 }) => (
  <svg width={width} height={height} viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.4728 2.46094C8.3834 2.46094 6.84947 4.05484 7.01159 6.31105C7.18032 8.65609 8.91191 10.3047 10.4728 10.3047C12.0327 10.3047 13.7622 8.65662 13.9341 6.30974C14.0959 4.09942 12.5559 2.46094 10.4728 2.46094ZM5.01674 6.4545C4.77357 3.0711 7.1916 0.460938 10.4728 0.460938C13.7603 0.460938 16.1723 3.12906 15.9288 6.45582L15.9288 6.45589C15.7035 9.53024 13.3715 12.3047 10.4728 12.3047C7.57507 12.3047 5.23815 9.5318 5.01675 6.45458L5.01674 6.4545ZM0.530868 21.7632C1.40123 16.6156 6.08664 14 10.4996 14C14.9127 14 19.598 16.6156 20.4689 21.7632L20.4689 21.7632C20.5506 22.2463 20.4739 22.7729 20.1934 23.21C19.8966 23.6722 19.385 24 18.7493 24H2.24996C1.61415 24 1.10272 23.6719 0.806233 23.2098C0.525848 22.7728 0.449183 22.2463 0.53086 21.7633M2.51988 22H18.4799C17.7755 18.1484 14.2115 16 10.4996 16C6.78768 16 3.22377 18.1484 2.51988 22Z" fill="black"/>
  </svg>

);

export const CalendarIcon = ({ width = 18, height = 17 }) => (
  <svg width={width} height={height} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_30054_168387)">
      <path d="M14.25 1.41667H13.5V0.708333C13.5 0.520472 13.421 0.340304 13.2803 0.207466C13.1397 0.0746278 12.9489 0 12.75 0C12.5511 0 12.3603 0.0746278 12.2197 0.207466C12.079 0.340304 12 0.520472 12 0.708333V1.41667H6V0.708333C6 0.520472 5.92098 0.340304 5.78033 0.207466C5.63968 0.0746278 5.44891 0 5.25 0C5.05109 0 4.86032 0.0746278 4.71967 0.207466C4.57902 0.340304 4.5 0.520472 4.5 0.708333V1.41667H3.75C2.7558 1.41779 1.80267 1.79129 1.09966 2.45524C0.396661 3.11919 0.00119089 4.01937 0 4.95833L0 13.4583C0.00119089 14.3973 0.396661 15.2975 1.09966 15.9614C1.80267 16.6254 2.7558 16.9989 3.75 17H14.25C15.2442 16.9989 16.1973 16.6254 16.9003 15.9614C17.6033 15.2975 17.9988 14.3973 18 13.4583V4.95833C17.9988 4.01937 17.6033 3.11919 16.9003 2.45524C16.1973 1.79129 15.2442 1.41779 14.25 1.41667ZM1.5 4.95833C1.5 4.39475 1.73705 3.85425 2.15901 3.45573C2.58097 3.05722 3.15326 2.83333 3.75 2.83333H14.25C14.8467 2.83333 15.419 3.05722 15.841 3.45573C16.2629 3.85425 16.5 4.39475 16.5 4.95833V5.66667H1.5V4.95833ZM14.25 15.5833H3.75C3.15326 15.5833 2.58097 15.3595 2.15901 14.9609C1.73705 14.5624 1.5 14.0219 1.5 13.4583V7.08333H16.5V13.4583C16.5 14.0219 16.2629 14.5624 15.841 14.9609C15.419 15.3595 14.8467 15.5833 14.25 15.5833Z" fill="#A36F01"/>
      <path d="M9 11.6875C9.62132 11.6875 10.125 11.2118 10.125 10.625C10.125 10.0382 9.62132 9.5625 9 9.5625C8.37868 9.5625 7.875 10.0382 7.875 10.625C7.875 11.2118 8.37868 11.6875 9 11.6875Z" fill="#A36F01"/>
      <path d="M5.25 11.6875C5.87132 11.6875 6.375 11.2118 6.375 10.625C6.375 10.0382 5.87132 9.5625 5.25 9.5625C4.62868 9.5625 4.125 10.0382 4.125 10.625C4.125 11.2118 4.62868 11.6875 5.25 11.6875Z" fill="#A36F01"/>
      <path d="M12.75 11.6875C13.3713 11.6875 13.875 11.2118 13.875 10.625C13.875 10.0382 13.3713 9.5625 12.75 9.5625C12.1287 9.5625 11.625 10.0382 11.625 10.625C11.625 11.2118 12.1287 11.6875 12.75 11.6875Z" fill="#A36F01"/>
    </g>
    <defs>
      <clipPath id="clip0_30054_168387">
        <rect width="18" height="17" fill="white"/>
      </clipPath>
    </defs>
  </svg>

);
