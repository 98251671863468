import React, { ReactElement } from 'react';
import classNames from "classnames";
import baseStyles from '../BaseSubscribeForm.module.scss';
import { SubscribeButton } from "../../SubscribeButton/SubscribeButton";
import { ModalLogo } from "../ModalLogo/ModalLogo";

interface IProps {
  onButtonClick: () => void;
}

export const ModalSuccessForm = ({ onButtonClick }: IProps): ReactElement => {
  return (
    <div className={baseStyles.modal}>
      <div className={baseStyles.wrapper}>
        <ModalLogo />
        <div className={classNames(baseStyles.title, baseStyles.textCentered)}>
          Вы успешно подписались
        </div>
        <div className={classNames(baseStyles.subTitle, baseStyles.subTitle_noMargin, baseStyles.textCentered)}>
          Пожалуйста, проверьте свой почтовый ящик, мы уже отправили вам первое письмо
        </div>
        <SubscribeButton className={baseStyles.mobileButton} onClick={onButtonClick}>
          Продолжить
        </SubscribeButton>
      </div>
    </div>
  );
};
