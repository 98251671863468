import React, { Dispatch, InputHTMLAttributes, KeyboardEventHandler, SetStateAction } from 'react';
import styles from './Input.module.scss';
import classNames from "classnames";
import { get } from 'lodash';
import { BackArrowIcon, CloseIcon } from "@kursk/components/ui/icons";
import { themeValue } from "@kursk/themes";

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: string | boolean;
  errors?: any;
  className?: string;
  close?: boolean;
  back?: boolean;
  handleOnBackClick?: Dispatch<SetStateAction<boolean>>;
  handleOnClick?: Dispatch<SetStateAction<string>>;
  iconWrapper?: string;
  offset?: string;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  autofocus?: boolean;
  inputStyles?: string
}

export const Input = React.forwardRef(({
  error,
  errors,
  className,
  close = false,
  iconWrapper,
  handleOnClick,
  back = false,
  handleOnBackClick,
  offset,
  autofocus = false,
  onKeyDown,
  inputStyles,
  ...props
}: IInputProps, ref: any) => {

  const errorMessage = error || get(errors, `${props.name}.message`) || false;
  const title = props.title || errorMessage || '';
  return (
    <div className={classNames([styles.wrapper, className])}>
      {
        back && handleOnBackClick && <div className={styles.iconWrapper_back}>
          <BackArrowIcon
            onClick={() => handleOnBackClick(false)}
            width={18}
            height={themeValue({
              bel: 12,
              default: 13
            })}
          />
        </div>
      }
      <input
        ref={ref}
        title={title}
        className={classNames([errorMessage && styles.input__error,
          styles.input, styles[offset],
          inputStyles && inputStyles])}
        onKeyDown={onKeyDown}
        autoFocus={autofocus}
        {...props}
      />
      {
        close && handleOnClick && <span className={styles[iconWrapper]}>
          <CloseIcon
            width={14}
            height={14}
            onClick={() => handleOnClick('')}
          />
        </span>
      }

      {errorMessage && <div className={styles.errorText}>{errorMessage}</div>}
    </div>);
});
