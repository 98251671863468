import React from 'react';
import { useEffect } from "react";
import { getCookie } from "cookies-next";

export const Bvi = () => {

  const bvi = React.useRef<any>(null);

  useEffect(() => {
    const bviConfig = {
      target: '.bvi-open',
      fontSize: 16,
      theme: 'white',
      images: 'grayscale',
      letterSpacing: 'normal',
      lineHeight: 'normal',
      speech: true,
      fontFamily: 'arial',
      builtElements: true,
      panelFixed: true,
      panelHide: false,
      reload: false,
      lang: 'ru-RU',
    };
    if (!bvi.current) {
      // Используем динамический импорт, т.к. bvi нельзя импортировать на уровне сервера
      (import('bvi')).then(({ Bvi }) => {
        bvi.current = new Bvi(bviConfig);
      });
    }
  }, []);

  const handleClickEvents = (e: any) => {
    const target = e.target as any;
    let anchor;
    if (target.tagName === 'a') {
      anchor = target
    } else {
      anchor = target.closest('a')
    }
    if (getCookie('bvi_panelActive')) {
      if (anchor) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        const href = anchor.getAttribute("href");
        if (!href) {
          return false
        }
        window.location.href = href;
      }
    }
  }

  React.useEffect(() => {
    document.addEventListener('click', handleClickEvents)
    return () => {
      document.removeEventListener('click', handleClickEvents)
    }
  }, []);

  return <></>;
}
