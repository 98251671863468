import { Modal } from "@kursk/components/ui/Modal/Modal";
import React from "react";
import styles from './LinkErrorModal.module.scss';

export const LinkErrorModal = ({ isOpen, onClose }) => (
  <Modal isOpen={isOpen} onRequestClose={onClose}>
    <div className={styles.header}>Ссылка недействительна.</div>
    <div className={styles.text}>Вернитесь на <a href='/'>портал</a> и повторите действия.</div>
  </Modal>
)
