import { useEffect } from "react";

export const useFixedBody = (isOpen: boolean) => {
  useEffect(() => {
    if (isOpen) {
      const x = window.scrollX;
      const y = window.scrollY;
      const disableScroll = () => {
        window.scrollTo(x, y);
      };
      window.addEventListener('scroll', disableScroll);
      return () => {
        window.removeEventListener('scroll', disableScroll);
      };
    }
  }, [isOpen]);
};
