import React, { ReactElement, useState } from 'react';
import styles from "./PhoneInput.module.scss";
import classNames from "classnames";
import { Control, Controller, FieldErrors, UseFormRegisterReturn } from "react-hook-form";
import { Input } from "@kursk/components/ui/Input/Input";
import _ from "lodash";

interface Props {
  errors: FieldErrors;
  control: Control<any, any>;
  register: (s: string) => UseFormRegisterReturn;
  customError?: string;
  onChange?: (string) => void;
}

export const PhoneInput = ({ errors, control, register, customError, onChange = _.noop }: Props): ReactElement => {
  const [focus, setFocus] = useState(false);
  const errorMessage = _.get(errors, 'phone.message') || false;

  const normalizePhoneNumber = (value) => {
    const fieldValue = value.replace(/[^[0-9]/g, "").replace(/\s/g, "").substr(0, 10);
    const mask = [fieldValue.slice(0, 3), fieldValue.slice(3, 6), fieldValue.slice(6, 8), fieldValue.slice(8, 10)];
    return mask?.join(" ").trim() || "";
  };

  return (
    <>
      <div className={styles.input_wrapper}>
        <div className={styles.phoneInput}>
          <div className={
            classNames([
              styles.telephoneCodeWrap,
              (errorMessage || customError) && styles.error,
              focus && styles.focused
            ])
          }>
            <span>+7</span>
          </div>
          <div className={styles.telephoneInput}>
            <Controller
              name='phone'
              control={control}
              render={({ field }) => {
                return <Input
                  {...register("phone")}
                  error={Boolean(errorMessage || customError)}
                  errors={errors}
                  onFocus={() => setFocus(true)}
                  onBlur={() => setFocus(false)}
                  onChange={(event) => {
                    const { value } = event.target;
                    event.target.value = normalizePhoneNumber(value);
                    if (onChange) onChange(event.target.value);
                    field.onChange(event.target.value);
                  }}
                />
              }}/>
          </div>
        </div>
        {(errorMessage || customError) && (
          <div className={styles.errorText}>
            {errorMessage || customError}
          </div>
        )}
      </div>
    </>
  );
};
