import React, { ReactElement, ReactNode } from 'react';
import Link from 'next/link';
import styles from './Button.module.scss';
import { UrlObject } from 'url';
import classNames from "classnames";

export type ButtonTypeEnum = 'greenPrimary' | 'greenPrimarySearch' | 'ghostButton' | 'vk' | 'esia';

export interface IButtonProps {
  text?: JSX.Element | string;
  type?: 'button' | 'submit';
  className?: string;
  href?: string | UrlObject;
  disabled?: boolean;
  onClick?: () => void;
  buttonType?: ButtonTypeEnum;
  children?: ReactNode | ReactElement | ReactElement[];
}

export function Button({
  type = 'button',
  className = null,
  href,
  text,
  disabled = false,
  onClick,
  buttonType = 'greenPrimary',
  children,
}: IButtonProps): ReactElement {
  const InnerButton = () => (
    <button
      type={type}
      className={classNames([
        styles.button,
        buttonType === 'greenPrimary' && styles.greenPrimary,
        buttonType === 'greenPrimarySearch' && styles.greenPrimarySearch,
        buttonType === 'ghostButton' && styles.ghostButton,
        buttonType === 'vk' && styles.vkButton,
        buttonType === 'esia' && styles.esiaButton,
        className && className
      ])}
      disabled={disabled}
      onClick={onClick}
    >
      {text && (<span className={styles.text}>{text}</span>)}
      {!text && children}
    </button>
  );
  return (
    <>
      {href ? (
        <Link href={href} passHref>
          <a>
            <InnerButton/>
          </a>
        </Link>
      ) : (
        <InnerButton/>
      )}
    </>

  );
}
