import React, { ReactElement, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import style from "./Header.transparent.module.scss";
import { BurgerMenuIcon, BviIcon, ProfileIcon, SearchIcon, LogoMain, LogoMainMobile } from "@kursk/components/ui/icons";
import {
  ProfileIcon as ProfileIconVladimir,
  SearchIcon as SearchIconVladimir,
} from "@kursk/components/ui/icons.vladimir";
import {
  ProfileIcon as ProfileIconSochi,
  SearchIcon as SearchIconSochi,
} from "@kursk/components/ui/icons.sochi";
import {
  ProfileIcon as ProfileIconLnr,
  SearchIcon as SearchIconLnr,
} from "@kursk/components/ui/icons.lnr";
import Link from "next/link";
import { regionContent } from "@kursk/content/regionContent";
import { getImageUrl } from "@common/utils/image";
import { useHideHeader } from "@common/hooks/useHideHeader";
import { useSelectSlice } from "@common/redux/selectors/useSelectSlice";
import { IMenuItem } from "@kursk/components/Layout/Layout";
import { authSlice, loadToken } from "@common/redux/slices/auth.slice";
import { AuthModal } from "@kursk/components/Layout/AuthModal/AuthModal";
import { LogoutModal } from "@kursk/components/Layout/logoutModal/LogoutModal";
import { SearchModal } from "@kursk/components/Layout/SearchModal/SearchModal";
import { useDispatchThunk } from "@common/redux/dispatchThunk";
import { Bvi } from "@common/components/Bvi/Bvi";
import { themeValue } from "@kursk/themes";
import { useOrientations } from "@common/hooks/useOrientation";
import { MenuDropdownButton } from "@kursk/components/ui/MenuDropdownButtonForTransparentHeader/MenuDropdownButton";


interface IProps {
  isBurgerOpen: boolean;
  mainMenu: IMenuItem[];
  setIsBurgerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  renderMenuLink: (e: IMenuItem) => ReactElement;
  showAuth?: boolean;
}

const profileButton: IMenuItem[] = [
  { title: 'Профиль', to: '/profile' },
];

const showDobroLogo = false;

export function HeaderTransparent(
  { setIsBurgerOpen, isBurgerOpen, mainMenu, showAuth = false }: IProps
): ReactElement {
  const orientation = useOrientations();
  const headerStatus = useHideHeader();
  const dispatchThunk = useDispatchThunk();
  const { isAuth, user } = useSelectSlice(authSlice);
  const profileRef = useRef(null);
  const [isOpenProfile, setIsOpenProfile] = useState(false);
  const [isOpenSearchModal, setIsOpenSearchModal] = useState(false);
  const [isOpenAuthModal, setIsOpenAuthModal] = useState(showAuth);
  const [isLogoutModal, setIsLogoutModal] = useState(false);

  const toggleProfile = (e) => {
    e.stopPropagation();
    setIsOpenProfile(!isOpenProfile);
  };
  const showAuthModal = () => {
    setIsOpenAuthModal(true);
  }
  const showSearchModal = () => {
    setIsOpenSearchModal(true);
  }
  const showLogoutModal = () => {
    setIsLogoutModal(true);
  }

  const renderMenuLink = (item: IMenuItem, withBackground) => {
    const checkIsLinkActive = () => false;
    const checkIsDropDownActive = () => false;

    return (
      item.links ? (
        <MenuDropdownButton
          className={classNames([
            style.header__menu__link,
            checkIsDropDownActive() && style.header__menu__link__active,
          ])}
          title={item.title}
          items={item.links}
          link={item.relativeLink}
          setIsBurgerOpen={setIsBurgerOpen}
          withBackground={withBackground}
        />
      ) : (
        <div
          key={item.title}
          className={classNames([style.header__menu__link, checkIsLinkActive() && style.header__menu__link__active])}
        >
          {item.target ?
            (<a
              className={style.header__menu__link_title}
              href={item.to}
              target={item.target}>{item.title}</a>) :
            (<Link href={item.to}>
              <a className={style.header__menu__link_title}>
                <span className={style.header__menu__link_text}>{item.title}</span>
              </a>
            </Link>)
          }
        </div>
      ))
  }

  const hideMenu = () => setIsOpenProfile(false);

  useEffect(() => {
    isBurgerOpen === true ? document.body.classList.add('no-scroll') : document.body.classList.remove('no-scroll');
  }, [isBurgerOpen, isAuth]);

  useEffect(() => {
    document.body.addEventListener('click', hideMenu);
    return () => document.body.removeEventListener('click', hideMenu);
  }, []);

  React.useEffect(() => {
    dispatchThunk(loadToken({}));
  }, [isAuth]);

  const withBackground = isBurgerOpen || headerStatus === 'fixed';

  return (
    <>
      <Bvi />
      <AuthModal
        isOpen={isOpenAuthModal}
        setIsOpen={setIsOpenAuthModal}
      />
      <LogoutModal
        isOpen={isLogoutModal}
        setIsOpen={setIsLogoutModal}
      />
      <SearchModal
        isOpen={isOpenSearchModal}
        setIsOpen={setIsOpenSearchModal}
      />
      <div className={classNames([
        style.header,
        isBurgerOpen && style.header_fixed,
        headerStatus === 'prepare' && style.header_prepare,
        headerStatus === 'hide' && style.header_hide,
        headerStatus === 'fixed' && style.header_view,
        withBackground && style.header_withBackground
      ])}>
        <div className={style.header__logoWrapper}>
          <button
            onClick={() => setIsBurgerOpen(!isBurgerOpen)}
            className={classNames([style.header__burgerMenuButton, isBurgerOpen && style.header__menuOpen])}>
            <BurgerMenuIcon height={33}/>
          </button>
          <Link href={'/'} passHref>
            <a className={style.header__logoLink} title={`${regionContent.title}`}>
              <div className={classNames([style.header__logo, showDobroLogo && style.header__logo_dobro])}>
                {orientation === 'desktop' ? (
                  <LogoMain />
                ) : (
                  <LogoMainMobile />
                )}
              </div>
            </a>
          </Link>
        </div>
        <div className={style.header__menu}>
          {mainMenu.map((item) => renderMenuLink(item, withBackground))}
        </div>
        <div className={style.header__buttons}>
          {regionContent.useBvi && (<button className={classNames(style.header__bvi, 'bvi-open')}>
            <BviIcon/>
          </button>)}
          <button className={style.header__search} onClick={showSearchModal}>
            {themeValue({
              vladimir: <SearchIconVladimir />,
              sochi: <SearchIconSochi />,
              lnr: <SearchIconLnr />,
              default: <SearchIcon />,
            })}
          </button>
          {!isAuth && <button className={style.header__profile} onClick={showAuthModal}>
            {themeValue({
              vladimir: <ProfileIconVladimir />,
              sochi: <ProfileIconSochi />,
              lnr: <ProfileIconLnr />,
              default: <ProfileIcon />,
            })}
          </button>}
          {isAuth && (
            <>
              <button className={style.header__profileAuth} onClick={toggleProfile} ref={profileRef}>
                {user?.avatar ? (
                  <span className={style.header__profileAuth__avatar}>
                    <img src={getImageUrl(user.avatar, 32, 32)} alt={user.avatar.realName}/>
                  </span>
                ) : (
                  <span className={style.header__profileAuth__label}>
                    {user?.name?.split('').shift()}
                  </span>
                )}
              </button>
              <div className={classNames(
                !isOpenProfile && style.hidden,
                isOpenProfile && style.open,
                style.dropdownList
              )}>
                <ul>
                  {profileButton.map((item) =>
                    <li key={item.to} className={style.listItem}><Link href={item.to}>{item.title}</Link></li>
                  )}
                  <li className={style.listItem}>
                    <button onClick={showLogoutModal}>Выйти</button>
                  </li>
                </ul>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}
