import { useEffect, useRef, useState } from 'react';

enum statuses {
  default = 'default', // меню отображается в хедере как обычно, (position: relative, display: true)
  // меню "перемещается" наверх для последующего отображения анимации (position: fixed, display: false)
  prepare = 'prepare',
  // отображается анимация
  fixed = 'fixed',
  hide = 'hide', // меню
}

const DELAY = 400;

export const useHideHeader = (): any => {
  const [headerStatus, setHeaderStatus] = useState(statuses.default);
  const height = useRef(0);

  useEffect(() => {
    const scrollHandler = (e) => {
      if (e.target.documentElement.scrollTop > DELAY && e.target.documentElement.scrollTop < height.current) {
        setHeaderStatus(statuses.fixed);
      }
      if (e.target.documentElement.scrollTop > DELAY && e.target.documentElement.scrollTop > height.current) {
        setHeaderStatus(prev => prev === 'default' ? statuses.prepare : statuses.hide);
      }
      if (e.target.documentElement.scrollTop < DELAY) {
        setHeaderStatus(statuses.default);
      }
      height.current = e.target.documentElement.scrollTop;
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return headerStatus;
};
